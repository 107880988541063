import React, {Component} from "react";
import Section from "./layouts/Section";
import {Link} from "react-router-dom";
import ServiceArea from "./layouts/ServiceArea";
import CtaArea from "./layouts/CtaArea";

class ServiceSingle extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={true} name={'Services Single'} title={'See our professional services'}>
                <div className="service-single-area pd-top-100 pd-bottom-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="servive-single-page-inner">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/service/1.jpg'} alt="img" />
                                    </div>
                                    <div className="details">
                                        <h3>Air Conditioner Repair</h3>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the rinting and typesetting industry
                                            has been the ndustry standard dummy text ever sincer they llam id
                                            condimentum purus In non ex at ligula fringilla bortis. Ut et mauris
                                            auctor, aliquet nulla sed, aliquam mauris. Vestibulum sed malesuada dolor.
                                            Integer fringilla odio a dolor aliquet, eu euismod lectus porttitor.
                                            Proin et libero nec eros eleifend commodo Phasellus sodales des volutpat
                                            diam, id sagittis purus egestas dapibus.
                                        </p>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="thumb">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/assets/img/service/2.jpg'}
                                                        alt="img" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="thumb">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/assets/img/service/3.jpg'}
                                                        alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                        <h3>Common Air Conditioner Problems</h3>
                                        <ul>
                                            <li><i className="fa fa-check-circle-o" />The air conditioner won't turn on.</li>
                                            <li><i className="fa fa-check-circle-o" />Warm air exits the supply registers.</li>
                                            <li><i className="fa fa-check-circle-o" />Reliable work from of conditioner their trade</li>
                                            <li><i className="fa fa-check-circle-o" />Water is pooling around the air</li>
                                            <li><i className="fa fa-check-circle-o" />The air conditioner won't turn on.</li>
                                        </ul>
                                        <h3>Supplying original parts for your appliance</h3>
                                        <ul>
                                            <li><i className="fa fa-check-circle-o" />The air conditioner won't turn conditioner on.</li>
                                            <li><i className="fa fa-check-circle-o" />Warm air exits the supply around registers.</li>
                                            <li><i className="fa fa-check-circle-o" />Reliable work from of their trade</li>
                                            <li><i className="fa fa-check-circle-o" />Water is pooling around the air</li>
                                            <li><i className="fa fa-check-circle-o" />The air conditioner won't turn on.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mt-lg-0 mt-5">
                                <div className="widget widget-all-service">
                                    <h3 className="widget-title">All Services</h3>
                                    <ul>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Fridge
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Refrigerator
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Salad Bars
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Freezers
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Cold Cooler
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Ice Maker
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Salad Bars
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Freezers
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget-all-service">
                                    <h3 className="widget-title">Parts We Replace</h3>
                                    <ul>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Thermostats
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Water filters
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Salad Bars
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Defrost heaters
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Cold Cooler
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Ice Maker
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Valves
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/service-single'} className="initiate-scripts">
                                                <i className="fa fa-angle-right" />Solenoids
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="widget widget-service-service-contact text-center">
                                    <div className="details">
                                        <i className="flaticon-emergency-call" aria-hidden="true" />
                                        <h4>Contact with us</h4>
                                        <p>123 8456 789</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Service Area Imported from Layouts */}
                <ServiceArea service={true} />

                {/* Cta Area Imported from Layouts */}
                <CtaArea service={true} />
            </Section>
        );
    }
}

export default ServiceSingle;