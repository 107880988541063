import React, {Component} from "react";
import Section from "./layouts/Section";
import {Link} from "react-router-dom";

class ShopDetails extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={true} name={'Shop Details'} title={'See our professional services'}>
                <div className="single-product-area pd-top-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="thumb mb-3 mb-lg-0">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/shop-details/1.png'} alt="img" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="product-description">
                                    <span className="customer-review"><i className="fa fa-star" />4.8 <span>(10 Customer review)</span></span>
                                    <h4 className="title">Maintenance & Repair</h4>
                                    <div className="price">
                                        <span>$160.00</span>
                                        <del>$190.00</del>
                                    </div>
                                    <p className="content">
                                        There are many variations of passages of Lorem
                                        Ipsum available, but the majority have suffered in some form.
                                    </p>
                                    <div className="quantity-wrap">
                                        <div className="quantity float-left">
                                            <input type="number" className="input-text qty text" placeholder="0" />
                                        </div>
                                        <button
                                            className="btn btn-gray-pd cart-btn"
                                            type="button"
                                            id="cart-btn">Add to cart</button>
                                    </div>
                                    <span className="d-block check-box-area">
                                        <input id="1checkbox" type="checkbox" />
                                        <label htmlFor="1checkbox">I Agree with the terms and conditions</label>
                                    </span>
                                    <div className="row">
                                        <div className="col-sm-6 col-7">
                                            <button className="w-100 btn btn-gray-pd" type="button">Buy it now</button>
                                        </div>
                                    </div>
                                    <div className="category">
                                        <span>Category: </span>
                                        <Link to={'/shop-details'} className="initiate-scripts">Watch</Link>
                                    </div>
                                    <div className="tags">
                                        <span>Tag: </span>
                                        <Link to={'/shop-details'} className="initiate-scripts">Watch,</Link>
                                        <Link to={'/shop-details'} className="initiate-scripts">Smart Watch,</Link>
                                        <Link to={'/shop-details'} className="initiate-scripts">Time</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="product-information">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                id="descr-tab"
                                                data-toggle="tab"
                                                href="#descr"
                                                role="tab"
                                                aria-controls="descr"
                                                aria-selected="true">Description</a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                id="reviews-tab"
                                                data-toggle="tab"
                                                href="#reviews"
                                                role="tab"
                                                aria-controls="reviews"
                                                aria-selected="false">Reviews</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="descr"
                                            role="tabpanel"
                                            aria-labelledby="descr-tab">

                                            <div className="row">
                                                <div className="col-lg-11">
                                                    <div className="description-tab-content">
                                                        <p>
                                                            There are many variations of passages of Lorem Ipsum
                                                            available, but the majority have suffered alteration
                                                            in some form, by injected humour, or randomised words
                                                            which don't look even slightly believable. There are
                                                            many variations of passages of Lorem Ipsum available,
                                                            but the majority have suffered alteration.
                                                        </p>
                                                        <p className="mb-0">
                                                            There are many variations of passages of Lorem Ipsum
                                                            available, but the majority have suffered alteration
                                                            in some form, by injected humour, or randomised words
                                                            which don't look even slightly believable. There are
                                                            many variations of passages of Lorem Ipsum available,
                                                            but the majority have suffered alteration in some form,
                                                            by injected humour, or randomised words which don't
                                                            look even slightly believable.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                            <div className="row">
                                                <div className="col-lg-10">
                                                    <div className="review-area">
                                                        <h6 className="review-title">Reviews (32)</h6>
                                                        <div className="single-review">
                                                            <div className="media">
                                                                <img
                                                                    className="media-left"
                                                                    src={process.env.PUBLIC_URL + '/assets/img/blog/c-01.png'}
                                                                    alt="img" />

                                                                <div className="media-body">
                                                                    <span>Jabel Ali</span>
                                                                    <p>CEO</p>
                                                                </div>
                                                            </div>
                                                            <p className="single-review-content">
                                                                There are many variations of passages of Lorem Ipsum
                                                                available, but the majority have suffered alteration
                                                                in some form, by injected humour, or randomised words
                                                                which don't look even slightly believable. There are
                                                                many variations of passages of Lorem Ipsum available,
                                                                but the majority have suffered alteration in some form,
                                                                by injected humour, or randomised words which don't
                                                                look even slightly believable.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="review-area">
                                                        <div className="single-review">
                                                            <div className="media">
                                                                <img
                                                                    className="media-left"
                                                                    src={process.env.PUBLIC_URL + '/assets/img/blog/c-02.png'}
                                                                    alt="img" />

                                                                <div className="media-body">
                                                                    <span>Jabel Ali</span>
                                                                    <p>CEO</p>
                                                                </div>
                                                            </div>
                                                            <p className="single-review-content">
                                                                There are many variations of passages of Lorem Ipsum
                                                                available, but the majority have suffered alteration
                                                                in some form, by injected humour, or randomised words
                                                                which don't look even slightly believable. There are
                                                                many variations of passages of Lorem Ipsum available,
                                                                but the majority have suffered alteration in some form,
                                                                by injected humour, or randomised words which don't
                                                                look even slightly believable.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h6 className="review-title">Your Reviews</h6>
                                            <form className="collier-form-wrap">
                                                <div className="row custom-gutters-16">
                                                    <div className="col-md-6">
                                                        <div className="single-input-wrap">
                                                            <label className="single-input-label">Review</label>
                                                            <textarea className="single-input textarea" cols="20" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="single-input-wrap">
                                                            <label>Name</label>
                                                            <input type="text" className="single-input" />
                                                        </div>
                                                        <div className="single-input-wrap">
                                                            <label>E-mail</label>
                                                            <input type="text" className="single-input" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 btn-wrapper">
                                                        <button type="button" className="btn btn-hrv">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shop-page-area single-shop-related-product pd-top-100 mb-5">
                    <div className="container">
                        <div className="section-title">
                            <h2 className="title">Related Products</h2>
                        </div>
                        <div className="row custom-gutters-16">
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/5.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop-details'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop-details'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/6.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop-details'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop-details'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/7.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop-details'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop-details'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/8.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop-details'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop-details'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
        );
    }
}

export default ShopDetails;