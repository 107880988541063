import React, {Component} from "react";
import {Link} from "react-router-dom";

class AboutArea extends Component {
    render() {
        return (
            <div className="about-us-area about-bg">
                <div className="container-fluid">
                    <div className="row justify-content-start">
                        <div className="col-lg-5 remove-col-padding">
                            <div className="about-image">
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/img/bg/about-bg.png'}
                                    className="img-fluid"
                                    alt="about us" />

                                <div className="hover">
                                    <a
                                        href="https://www.youtube.com/watch?v=s6NHxfKUkUo"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="btn-ripple-animate video-play-btn video mfp-iframe">
                                        <i className="fa fa-play" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 align-self-center">
                            <div className="about-area-right mb-0">
                                <div className="section-title about text-left mb-0">
                                    <span className="subtitle">About the Airtemp Mechanical Service  </span>
                                    <h2 className="title">Over
60 years in the industry.</h2>
                                </div>
                                <div className="faq-wrap">
                                    <ul className="accordion">
                                        <li className="active">
                                            <div className="question"><h4> All Standard Services</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                                Commercial Air Conditioning,Heating Ventilation Systems,Ice Machines,Refrigeration Equipment,Restaurant Equipment,Walk-In Boxes
,Freezers,Display Cases,Boilers,Chillers,so much more...

                                            </div>
                                        </li>
                                        <li>
                                            <div className="question"><h4> 60+ Years Experience</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                            AirTemp Mechanical Services is an industry-leading mechanical contractor in the HVAC/R
industry for over four years. It’s a fully licensed and insured company with General Liability
Insurance coverage from $1,000,000 to $5,000,000. Our management team that has well over
60 years of combined experience in the industry. AirTemp continues to grow and expand as
technology changes and the needs of the industry and our valued clientele evolves. Efficiency,
reliability and infallible service are all central to our mission.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="question"><h4> Our vehicles</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                            Our service vehicles are well stocked with the latest tools and instruments and commonly
used repair parts to ensure equipment downtime is kept at its minimum. Our team works fast
to source OEM parts and equipment at the lowest competitive market price directly from
authorized distributors and local manufacturer’s reps and vendors. Our company is also driven
by Green Energy.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="question"><h4> Our Technicians</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                            Our skilled and trained service
technicians are handpicked and carefully selected based on key combination of qualifications,
such as extensive field experience and industry-must certifications. All our technicians hold
certifications such as universal EPA licensing, Certificate of Fitness for torch use. We adhere to
OSHA safety compliance regulations.
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="btn-wrapper">
                                    <Link to={'/about'} className="btn-hrv initiate-scripts">More About Here</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutArea;