import React, {Component} from "react";
import {Link} from "react-router-dom";

class TestimonialArea extends Component {
    render() {
        return (
            <div className="testimonial-area testimonial-bg">
                <div className="customer-feedback">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-md-offset-3 col-md-8 col-sm-offset-2 col-sm-8">
                                <div className="owl-carousel feedback-slider">
                                    {/* slider item */}
                                    <div className="feedback-slider-item">
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/img/testimonial/01.png'}
                                            className="center-block img-circle"
                                            alt="Customer Feedback" />
                                        <h3 className="customer-name">Carl Frederick</h3>
                                        <span className="title">I highly recommend AirTemp</span>
                                        <div className="subline" />
                                        <p>
                                        We had the pleasure of working with AirTemp Mechanical Services for the installation of our LG CoolArt Split System. Numerous HVAC installers quoted us; however, AirTemp quote, attention-to-detail and professionalism made them the best option for my wife and I.  They provided us with options along with the Pros and Cons relative to the different systems available throughout the industry.  The installation was completed on-time and on-budget.

We are extremely happy with the new system, as well as the clean and professional job they did with the installation. I highly recommend AirTemp Mechanical Services for your HVAC needs.
                                        </p>
                                        <div className="line" />
                                        <ul className="feedback-social">
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-facebook-f" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-pinterest" aria-hidden="true" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* /slider item */}

                                    {/* slider item */}
                                    <div className="feedback-slider-item">
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/img/testimonial/02.png'}
                                            className="center-block img-circle"
                                            alt="Customer Feedback" />
                                        <h3 className="customer-name">ryan pers</h3>
                                        <span className="title">Couldn’t of been more happier!</span>
                                        <div className="subline" />
                                        <p>
                                        Couldn’t of been more happier! I’m not one to leave a review but I wanted to take the time and recognize this company. From day 1, these guys have been honest, insightful and passionate in everything they do. You can tell that the owners and employees love what they do, as you see it in their work. Best of all when they say start time is at 7am, better believe they are outside waiting at 6:15am. This past Thanksgiving my freezer goes out, made one call and in no time these guys was there!!  This company continuously earns my respect and trust.
                                        </p>
                                        <div className="line" />
                                        <ul className="feedback-social">
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-facebook-f" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-pinterest" aria-hidden="true" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* /slider item */}

                                    {/* slider item */}
                                    <div className="feedback-slider-item">
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/img/testimonial/03.png'}
                                            className="center-block img-circle"
                                            alt="Customer Feedback" />
                                        <h3 className="customer-name">Maria Knutsen</h3>
                                        <span className="title">Very professional </span>
                                        <div className="subline" />
                                        <p>
                                        I called the company and got a reply from the owner right away.  Very professional and explained in details the problem and the best solution.
I was very satisfied.  Reasonable priced and very fair.  I highly recommend this company.
                                        </p>
                                        <div className="line" />
                                        <ul className="feedback-social">
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-facebook-f" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-pinterest" aria-hidden="true" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* /slider item */}
                                </div>{/* /End feedback-slider */}

                                {/* side thumbnail */}
                                <div className="feedback-slider-thumb hidden-xs">
                                    <div className="thumb-prev">
                                        <div>
                                            <img
                                                src={process.env.PUBLIC_URL + '/assets/img/testimonial/02.png'}
                                                alt="Customer Feedback" />
                                            <h3 className="customer-name">Maria Knutsen</h3>
                                            <span className="title">Very professional</span>
                                        </div>
                                    </div>
                                    <div className="thumb-next">
                                        <div>
                                            <img
                                                src={process.env.PUBLIC_URL + '/assets/img/testimonial/03.png'}
                                                alt="Customer Feedback" />
                                            <h3 className="customer-name">ryan pers</h3>
                                            <span className="title">Couldn’t of been more happier!</span>
                                        </div>
                                    </div>
                                </div>
                                {/* /side thumbnail */}
                            </div>{/* /End col */}
                        </div>{/* /End row */}
                    </div>{/* /End container */}
                </div>{/* /End customer-feedback */}
            </div>
        );
    }
}

export default TestimonialArea;