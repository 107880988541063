import React, {Component} from "react";
import TopBar from "./TopBar";
import InfoBar from "./InfoBar";
import NavBar from "./NavBar";
import Footer from "./Footer";
import BreadCrumbArea from "./BreadCrumbArea";

class Section extends Component {
    render() {
        return (
            <>
                <TopBar />
                <InfoBar />
                <NavBar design={this.props.design} />
                {this.props.breadcrumb ? <BreadCrumbArea name={this.props.name} title={this.props.title} /> : ''}
                {this.props.children}
                <Footer />
            </>
        );
    }
}

export default Section;