import React, {Component} from "react";

class FacilitiesArea extends Component {
    render() {
        return (
            <div className="facilities-area pd-top-100 pd-bottom-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="section-title">
                                <span className="subtitle">choose us</span>
                                <h2 className="title">Why Our Service Company?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="single-facilities-item item-bg">
                                <div className="icon">
                                    <i className="flaticon-emergency-call" aria-hidden="true" />
                                </div>
                                <h4 className="title">24X7 Support Services</h4>
                                <p className="details">Dont be left uncomfortable we offer service and support all day and night let us get you the help ypu need</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="single-facilities-item item-bg">
                                <div className="icon">
                                    <i className="flaticon-cogwheel" aria-hidden="true" />
                                </div>
                                <h4 className="title">We Are Certified</h4>
                                <p className="details">Our technicians hold
certifications,universal EPA licensing, OHSA certification. </p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="single-facilities-item item-bg">
                                <div className="icon">
                                    <i className="flaticon-man" aria-hidden="true" />
                                </div>
                                <h4 className="title">We're Responsibility</h4>
                                <p className="details">fully licensed and insured company with General Liability
Insurance coverage from $1,000,000 to $5,000,000.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FacilitiesArea;