import React, {Component} from "react";

class ServiceMoreArea extends Component {
    render() {
        return (
            <div className="service-more pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="services-content">
                                <h3 className="title">More Details of Our Services</h3>
                                <div className="single-service-content">
                                    <div className="service-number" data-number="1">
                                    </div>
                                    <div className="details">
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd ggren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et acusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                            amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                            diam nonumy eirmo.
                                        </p>
                                    </div>
                                </div>
                                <div className="single-service-content">
                                    <div className="service-number" data-number="2">
                                    </div>
                                    <div className="details">
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd ggren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et acusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                            amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                            diam nonumy eirmo.
                                        </p>
                                    </div>
                                </div>
                                <div className="single-service-content">
                                    <div className="service-number" data-number="3">
                                    </div>
                                    <div className="details">
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd ggren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et acusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                            amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                            diam nonumy eirmo.
                                        </p>
                                    </div>
                                </div>
                                <div className="single-service-content">
                                    <div className="service-number" data-number="4">
                                    </div>
                                    <div className="details">
                                        <p>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd ggren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                            eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
                                            voluptua. At vero eos et acusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                            amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                            diam nonumy eirmo.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceMoreArea;