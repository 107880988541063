import React, {Component} from "react";
import {Link} from "react-router-dom";

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {search: false}
    }

    render() {
        return (
            <>
                <div
                    className={this.state.search ? 'body-overlay active' : 'body-overlay'}
                    id="body-overlay"
                    onClick={() => this.setState({search: false})} />

                <div
                    className={this.state.search ? 'search-popup active' : 'search-popup'}
                    style={{display: this.state.search ? 'block' : 'none'}}
                    id="search-popup">

                    <form className="search-form">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Search....." />
                        </div>
                        <button type="submit" className="submit-btn"><i className="fa fa-search" /></button>
                    </form>
                </div>

                <nav className={this.props.design === 'home_2' ? 'navbar navbar-area navbar-expand-lg nav-style-02' : 'navbar navbar-area navbar-expand-lg nav-style-01'}>
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <div className="logo-wrapper mobile-logo">
                                <Link to={'/'} className="logo initiate-scripts">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="logo" />
                                </Link>
                            </div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#coolair_main_menu"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon" />
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="coolair_main_menu">
                            <ul className="navbar-nav">
                            <li><Link to={'/'} className="initiate-scripts current-menu-item">Home</Link></li>

                                <li><Link to={'/about'} className="initiate-scripts">About</Link></li>
                                <li className="menu-item-has-children">
                                    <Link to={'/service'} className="initiate-scripts">Service</Link>
                                    <ul className="sub-menu">
                                        <li><Link to={'/service'} className="initiate-scripts">Service</Link></li>
                                        <li><Link to={'/service-single'} className="initiate-scripts">Service Single</Link></li>
                                    </ul>
                                </li>
                                <li><Link to={'/contact'} className="initiate-scripts">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="nav-right-content">
                            <ul>
                                <li
                                    className="search"
                                    id="search"
                                    onClick={(event) => {event.preventDefault(); this.setState({search: true})}}>
                                    <i className="fa fa-search" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default NavBar;