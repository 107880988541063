import React, {Component} from "react";

class CtaArea extends Component {
    render() {
        return (
            <div className={this.props.service ? 'cta-area service-page' : 'cta-area cta-bg home2'}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cta-wrapper">
                                <div className="left-content">
                                    <div className="single-info-item">
                                        <div className="icon">
                                            <i className="fa fa-envelope" />
                                        </div>
                                        <div className="content">
                                            <span className="details">HVAC Maintenance  & <br /> Repair Services </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="right-content">
                                    <div className="single-info-item">
                                        <div className="icon">
                                            <i className="fa fa-phone" />
                                        </div>
                                        <div className="content">
                                            <span className="details">+1 (718) - 219-4063 <br /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CtaArea;