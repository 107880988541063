import React, {Component} from "react";
import {Link} from "react-router-dom";

class ChooseArea extends Component {
    render() {
        return (
            <div className="choose-area choose-bg pd-top-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="section-title">
                                <span className="subtitle">We are the best in our specialty</span>
                                <h2 className="title">Why Choose Us</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                                </p>
                            </div>
                            <div className="content-box-style-06">
                                <div className="row margin-top-50">
                                    <div className="col-md-4">
                                        <ul className="nav nav-tabs">
                                            <li><a className="active" data-toggle="tab" href="#home">Installation</a></li>
                                            <li><a data-toggle="tab" href="#menu1">Repair</a></li>
                                            <li><a data-toggle="tab" href="#menu2">Maintenance </a></li>
                                            <li><a data-toggle="tab" href="#menu3">Operations</a></li>
                                            <li><a data-toggle="tab" href="#menu4">Efficiency</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="tab-content">
                                            <div id="home" className="tab-pane fade in active show">
                                                <h3 className="title margin-bottom-30">Air Conditioning Installation</h3>
                                                <p>
                                                    We install state-of-the-art high efficiency residential
                                                    air conditioning units that will save
                                                    homeowners hundreds of dollars a year.
                                                </p>
                                                <p>
                                                    We install state-of-the-art
                                                    high efficiency residential air
                                                    conditioning units that will save
                                                </p>
                                                <div className="btn-wrapper align-self-center margin-top-30">
                                                    <Link
                                                        to={'/service'}
                                                        className="btn-hrv initiate-scripts">Learn More</Link>
                                                </div>
                                            </div>
                                            <div id="menu1" className="tab-pane fade">
                                                <h3 className="title margin-bottom-30">Air Conditioning Repair</h3>
                                                <p>
                                                    Is your AC not working right this summer?
                                                    Give us a call. We will take the
                                                    time to properly diagnose your problem.
                                                </p>
                                                <div className="btn-wrapper align-self-center margin-top-30">
                                                    <Link
                                                        to={'/service'}
                                                        className="btn-hrv initiate-scripts">Learn More</Link>
                                                </div>
                                            </div>
                                            <div id="menu2" className="tab-pane fade">
                                                <h3 className="title margin-bottom-30">Preventative Maintenance</h3>
                                                <p>
                                                    Met dolore magna aliqua. Ut enim ad minim veniam exercitation
                                                    ullamco laboris nisi ut aliquip ex ea Met dolore magna aliqua.
                                                    Ut enim ad minim veniam exercitation ullamco laboris nisi.
                                                </p>
                                                <div className="btn-wrapper align-self-center margin-top-30">
                                                    <Link
                                                        to={'/service'}
                                                        className="btn-hrv initiate-scripts">Learn More</Link>
                                                </div>
                                            </div>
                                            <div id="menu3" className="tab-pane fade">
                                                <h3 className="title margin-bottom-30">Operations</h3>
                                                <p>
                                                    Met dolore magna aliqua. Ut enim ad minim
                                                    veniam exercitation ullamco laboris nisi
                                                    ut aliquip ex ea Met dolore magna aliqua.
                                                    Ut enim ad minim veniam exercitation ullamco laboris nisi.
                                                </p>
                                                <div className="btn-wrapper align-self-center margin-top-30">
                                                    <Link
                                                        to={'/service'}
                                                        className="btn-hrv initiate-scripts">Learn More</Link>
                                                </div>
                                            </div>
                                            <div id="menu4" className="tab-pane fade">
                                                <h3 className="title margin-bottom-30">Energy Efficiency</h3>
                                                <p>
                                                    Met dolore magna aliqua. Ut enim ad minim veniam
                                                    exercitation ullamco laboris nisi ut aliquip ex
                                                    ea Met dolore magna aliqua. Ut enim ad minim
                                                    veniam exercitation ullamco laboris nisi.
                                                </p>
                                                <div className="btn-wrapper align-self-center margin-top-30">
                                                    <Link
                                                        to={'/service'}
                                                        className="btn-hrv initiate-scripts">Learn More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChooseArea;