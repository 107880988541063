import React, {Component} from "react";
import {Link} from "react-router-dom";

class AskArea extends Component {
    render() {
        return (
            <div className="ask-area ask-bg">
                <div className="container">
                    <div className="row justify-content-around">
                        <div className="col-lg-6 col-md-7">
                            <div className="left-content">
                                <h3 className="subtitle"> Do you have any questions?</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-5">
                            <div className="right-content">
                                <div className="btn-wrapper">
                                    <Link to={'/contact'} className="boxed-btn white initiate-scripts"> Ask Here</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AskArea;