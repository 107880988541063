import React, {Component} from "react";
import {Link} from "react-router-dom";

class TopBar extends Component {
    render() {
        return (
            <div className="topbar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="left-contnet">
                                <span>
                                    <i className="fa fa-map-marker" aria-hidden="true" />
                                    895 Liberty Ave, Brooklyn, NY 11208
                                </span>
                                <span>
                                    <a href="mailTo:info@airtempmechserv.com">
                                        <i className="fa fa-envelope" />
                                        info@airtempmechserv.com
                                    </a>
                                </span>
                            </div>
                            <div className="right-contnet">
                                <ul className="social-icon">
                                    <li>
                                        <Link to={'/'} className="initiate-scripts">
                                            <i className="fa fa-facebook"/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/'} className="initiate-scripts">
                                            <i className="fa fa-twitter" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/'} className="initiate-scripts">
                                            <i className="fa fa-google-plus" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/'} className="initiate-scripts">
                                            <i className="fa fa-linkedin" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBar;