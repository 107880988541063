import React, {Component} from "react";
import Section from "./layouts/Section";
import Header from "./layouts/Header";
import CtaArea from "./layouts/CtaArea";
import CooliItemArea from "./layouts/CooliItemArea";
import AboutArea from "./layouts/AboutArea";
import AskArea from "./layouts/AskArea";
import ServiceArea from "./layouts/ServiceArea";
import CountArea from "./layouts/CountArea";
import FacilitiesArea from "./layouts/FacilitiesArea";
import ProjectArea from "./layouts/ProjectArea";
import HistoryArea from "./layouts/HistoryArea";
import ChooseArea from "./layouts/ChooseArea";
import BlogArea from "./layouts/BlogArea";
import TestimonialArea from "./layouts/TestimonialArea";
import NewsletterArea from "./layouts/NewsletterArea";

class HomeTwo extends Component {
    render() {
        return (
            <Section design={'home_2'} breadcrumb={false}>
                <Header design={'home_2'} />
                <CtaArea />
                <CooliItemArea design={'home_2'} />
                <AboutArea />
                <AskArea />
                <ServiceArea service={false} />
                <CountArea />
                <FacilitiesArea />
                <ProjectArea />
                <CtaArea service={false} />
                <HistoryArea />
                <ChooseArea />
                <BlogArea />
                <TestimonialArea />
                <NewsletterArea />
            </Section>
        );
    }
}

export default HomeTwo;