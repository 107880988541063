import React, {Component} from "react";
import {Link} from "react-router-dom";

class BreadCrumbArea extends Component {
    render() {
        return (
            <div className="breadcumb-area breadcrumb-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcumb-inner">
                                <ul className="page-lists">
                                    <li><Link to={'/'} className="initiate-scripts">Home</Link></li>
                                    <li>{this.props.name}</li>
                                </ul>
                                <h5 className="title">{this.props.title}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BreadCrumbArea;