import React, {Component} from "react";
import {Link} from "react-router-dom";
import Section from "./layouts/Section";
import NewsletterArea from "./layouts/NewsletterArea";

class BlogDetails extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={true} name={'blog details'} title={'Our updates & news'}>
                <div className="blog-details">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="blog-details-content">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/blog/blog-details.png'} alt="blog detials" />
                                    </div>
                                    <ul className="post-meta">
                                        <li><Link to={'/blog-details'} className="initiate-scripts">Posted By: Avily Tragon</Link></li>
                                        <li><Link to={'/blog-details'} className="initiate-scripts">Category: Airconditioning</Link></li>
                                        <li><Link to={'/blog-details'} className="initiate-scripts">Comments (645)</Link></li>
                                        <li><Link to={'/blog-details'} className="initiate-scripts">15 January 2020</Link></li>
                                    </ul>
                                    <h4 className="title">
                                        All standard service, well done of 18 years experience cooly agency
                                    </h4>
                                    <p className="details">
                                        Diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                                        Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
                                        sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                        diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                                        erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
                                        et ea rebum. Stet clita kasd gubergren, no sea takimata
                                        sanctus est Lorem ipsum dolor sit amet.
                                    </p>
                                </div>
                                <div className="blog-single-content">
                                    <div className="content-wrapper">
                                        <h4 className="title">Aircondition repairing system, how?</h4>
                                        <p>
                                            Lobore et dolore magna aliquyam erat, sed diam voluptua.
                                            At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
                                            dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                                            aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                                            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
                                            sanctus est Lorem ipsum dolor sit amet.
                                        </p>
                                        <p>
                                            Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                                            eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                                            gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                                            nonumy eirmod tempor invidunt ut labore et
                                        </p>
                                        <ul className="numbered-list">
                                            <li>
                                                At vero eos et accusam et justo
                                                duo dolores et ea rebum. Stet
                                                clita kasd gubergren, no sea
                                                takimata sanctus est Lorem ipsum
                                            </li>
                                            <li>
                                                At vero eos et accusam et justo
                                                duo dolores et ea rebum. Stet
                                                clita kasd gubergren, no sea
                                                takimata sanctus est Lorem ipsum
                                            </li>
                                            <li>
                                                At vero eos et accusam et justo
                                                duo dolores et ea rebum. Stet
                                                clita kasd gubergren, no sea
                                                takimata sanctus est Lorem ipsum
                                            </li>
                                        </ul>
                                        <p>
                                            Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                                            eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                                            gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                                            nonumy eirmod tempor invidunt ut labore et
                                        </p>
                                        <p>
                                            Lobore et dolore magna aliquyam erat, sed diam voluptua.
                                            At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                            clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
                                            dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                            sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                                            aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                                            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
                                            sanctus est Lorem ipsum dolor sit amet.
                                        </p>
                                        <blockquote className="blockquote">
                                            <p>
                                                Lobore et dolore magna aliquyam erat, sed diam voluptua.
                                                At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                                clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
                                                dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                                                sed diam nonumy eirmod tempor invidunt ut labore et dolore magna
                                                aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                                                dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
                                                sanctus est Lorem ipsum dolor sit amet.
                                            </p>
                                        </blockquote>
                                        <p>
                                            Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                                            ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero
                                            eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
                                            gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                                            nonumy eirmod tempor invidunt ut labore et
                                        </p>
                                    </div>
                                    <div className="blog-content-image">
                                        <div className="thumb">
                                            <img
                                                src={process.env.PUBLIC_URL + '/assets/img/blog/item-01.png'}
                                                alt="blog content" />
                                            <div className="img-overlay">
                                                <div className="icon">
                                                    <i className="fa fa-search-plus" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="thumb">
                                            <img
                                                src={process.env.PUBLIC_URL + '/assets/img/blog/item-02.png'}
                                                alt="blog content" />
                                            <div className="img-overlay">
                                                <div className="icon">
                                                    <i className="fa fa-search-plus" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="thumb">
                                            <img
                                                src={process.env.PUBLIC_URL + '/assets/img/blog/item-03.png'}
                                                alt="blog content" />
                                            <div className="img-overlay">
                                                <div className="icon">
                                                    <i className="fa fa-search-plus" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="thumb">
                                            <img
                                                src={process.env.PUBLIC_URL + '/assets/img/blog/item-04.png'}
                                                alt="blog content" />
                                            <div className="img-overlay">
                                                <div className="icon">
                                                    <i className="fa fa-search-plus" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="entry-comment">
                                    <h3 className="title">Comments</h3>
                                    <ul className="comment-list">
                                        <li>
                                            <div className="single-comment-item">
                                                <div className="thumb">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/assets/img/blog/c-01.png'}
                                                        alt="comments" />
                                                </div>
                                                <div className="content">
                                                    <span className="reply">
                                                        <Link to={'/blog-details'} className="initiate-scripts">Reply</Link>
                                                    </span>
                                                    <h4 className="name">Ranon Nine</h4>
                                                    <p>
                                                        Lusto duo dolores et ea rebum. Stet clita kasd gubergren,
                                                        no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-comment-item">
                                                <div className="thumb">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/assets/img/blog/c-02.png'}
                                                        alt="comments" />
                                                </div>
                                                <div className="content">
                                                    <span className="reply">
                                                        <Link to={'/blog-details'} className="initiate-scripts">Reply</Link>
                                                    </span>
                                                    <h4 className="name">Ranon Nine</h4>
                                                    <p>
                                                        Lusto duo dolores et ea rebum. Stet clita kasd gubergren,
                                                        no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-comment-item">
                                                <div className="thumb">
                                                    <img
                                                        src={process.env.PUBLIC_URL + '/assets/img/blog/c-03.png'}
                                                        alt="comments" />
                                                </div>
                                                <div className="content">
                                                    <span className="reply">
                                                        <Link to={'/blog-details'} className="initiate-scripts">Reply</Link>
                                                    </span>
                                                    <h4 className="name">Ranon Nine</h4>
                                                    <p>
                                                        Lusto duo dolores et ea rebum. Stet clita kasd gubergren,
                                                        no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div className="comment-form-area">
                                    <h3 className="title">Leave a Reply</h3>
                                    <form className="comments-entry-form">
                                        <div className="form-group textarea">
                                            <textarea className="form-control" placeholder="Write your message..." cols="30" rows="5" />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Name*" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email*" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="number" className="form-control" placeholder="Phone Number" />
                                        </div>
                                        <div className="input_field checkbox_option">
                                            <input type="checkbox" id="cb1" />
                                            <label htmlFor="cb1">
                                                Save my name, email in this browser for the next time I comment.
                                            </label>
                                        </div>
                                        <button type="submit" className="submit-btn">Post Comment</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Newsletter Area Imported from Layouts */}
                <NewsletterArea />
            </Section>
        );
    }
}

export default BlogDetails;