import React, {Component} from "react";

class NewsletterArea extends Component {
    render() {
        return (
            <div className="cta-area newsletter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cta-wrapper">
                                <div className="left-content">
                                    <div className="single-info-item">
                                        <div className="icon">
                                            <i className="fa fa-envelope" />
                                        </div>
                                        <div className="content">
                                            <span className="details">SignUp For Newsletter </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="right-content">
                                    <div className="newsletter-subcribe">
                                        <form id="news-subcribeform" className="subcribe-form">
                                            <div className="form-group">
                                                <input type="text" className="form-control" placeholder="Your mail here..." name="mail" required="" />
                                                <button type="submit" className="boxed-btn subcribe-submit">Subcribe</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewsletterArea;