import React, {Component} from "react";
import {Link} from "react-router-dom";
import Section from "./layouts/Section";

class Shop extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={true} name={'Shop'} title={'See our professional Shop'}>
                <div className="shop-page-area pd-top-100">
                    <div className="container">
                        <div className="row custom-gutters-16">
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/1.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/2.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/3.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/4.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/5.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/6.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/7.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/8.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/9.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/10.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/11.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-sm-6">
                                <div className="single-shop">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/shop/12.jpg'} alt="shop" />
                                        <div className="cart-btn">
                                            <div className="cart-btn-wrap">
                                                <Link className="btn btn-red" to={'/shop'}>
                                                    Add to card <i className="fa fa-shopping-cart" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="content">
                                        <div className="rating">
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                            <i className="fa fa-star" />
                                        </div>
                                        <Link to={'/shop'} className="initiate-scripts">Maintenance & Repair</Link>
                                        <div className="price">
                                            <span>$160.00</span>
                                            <del>$190.00</del>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 blog-page p-0">
                    <nav aria-label="Page navigation">
                        <ul className="pagination pagination-2 justify-content-center">
                            <li className="page-item">
                                <Link
                                    className="page-link d-none d-sm-inline-block initiate-scripts"
                                    to={'/shop'}
                                    tabIndex="-1"
                                    aria-disabled="true">
                                    <i className="fa fa-arrow-left" aria-hidden="true" />
                                </Link>
                            </li>
                            <li className="page-item active">
                                <Link className="page-link initiate-scripts" to={'/shop'}>1</Link>
                            </li>
                            <li className="page-item">
                                <Link className="page-link initiate-scripts" to={'/shop'}>2</Link>
                            </li>
                            <li className="page-item">
                                <Link className="page-link initiate-scripts" to={'/shop'}>....</Link>
                            </li>
                            <li className="page-item">
                                <Link className="page-link initiate-scripts" to={'/shop'}>32</Link>
                            </li>
                            <li className="page-item">
                                <Link
                                    className="page-link d-none d-sm-inline-block initiate-scripts"
                                    to={'/shop'}>
                                    <i className="fa fa-arrow-right" aria-hidden="true" />
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </Section>
        );
    }
}

export default Shop;