import React, {Component} from "react";
import Section from "./layouts/Section";
import AskArea from "./layouts/AskArea";
import CooliItemArea from "./layouts/CooliItemArea";
import CountArea from "./layouts/CountArea";
import FacilitiesArea from "./layouts/FacilitiesArea";
import CtaArea from "./layouts/CtaArea";
import HistoryArea from "./layouts/HistoryArea";

class About extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={true} name={'About Us'} title={'Welcome to AirTemp Mechanical Service'}>
                <div className="about-area about-page">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="about-wrapper">
                                    <div className="about-image">
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/img/bg/about-bg.png'}
                                            className="img-fluid"
                                            alt="about" />
                                        <div className="hover">
                                            <a
                                                href="https://www.youtube.com/watch?v=s6NHxfKUkUo"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="btn-ripple-animate video-play-btn video mfp-iframe">
                                                <i className="fa fa-play" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="about-area-right">
                                    <div className="section-title about text-left">
                                        <span className="subtitle">About the AirTemp Mechanical Service   </span>
                                        <h2 className="title about-page">Well done of 18 years experience cooly agency</h2>
                                    </div>
                                    <div className="faq-wrap about-page">
                                        <ul className="accordion">
                                            <li className="active">
                                                <div className="question"><h4> All Standard Services</h4>
                                                    <div className="plus-minus-toggle collapsed" />
                                                </div>
                                                <div className="answer">
                                                    diam voluptua. At vero eos et accusam et justo duo dolores et
                                                    ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                                                    est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                                                    consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                                    invidunt ut labore et dolore magna aliquyam erat, sed diam
                                                    voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                                                    Stet clita kasd gubergren, no sea takimata sanctus
                                                    est Lorem ipsum dolor sit amet..
                                                </div>
                                            </li>
                                            <li>
                                                <div className="question"><h4> 18+ Years Experience</h4>
                                                    <div className="plus-minus-toggle collapsed" />
                                                </div>
                                                <div className="answer">
                                                    diam voluptua. At vero eos et accusam et justo duo dolores et
                                                    ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                                                    est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                                                    consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                                    invidunt ut labore et dolore magna aliquyam erat, sed diam
                                                    voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                                                    Stet clita kasd gubergren, no sea takimata sanctus
                                                    est Lorem ipsum dolor sit amet..
                                                </div>
                                            </li>
                                            <li>
                                                <div className="question"><h4> Online Schedule</h4>
                                                    <div className="plus-minus-toggle collapsed" />
                                                </div>
                                                <div className="answer">
                                                    diam voluptua. At vero eos et accusam et justo duo dolores et
                                                    ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                                                    est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                                                    consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                                    invidunt ut labore et dolore magna aliquyam erat, sed diam
                                                    voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                                                    Stet clita kasd gubergren, no sea takimata sanctus
                                                    est Lorem ipsum dolor sit amet..
                                                </div>
                                            </li>
                                            <li>
                                                <div className="question"><h4> Financing Available</h4>
                                                    <div className="plus-minus-toggle collapsed" />
                                                </div>
                                                <div className="answer">
                                                    diam voluptua. At vero eos et accusam et justo duo dolores et
                                                    ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                                                    est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                                                    consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                                                    invidunt ut labore et dolore magna aliquyam erat, sed diam
                                                    voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                                                    Stet clita kasd gubergren, no sea takimata sanctus
                                                    est Lorem ipsum dolor sit amet..
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ask Area Imported form Layouts */}
                <AskArea />

                {/* Cooli Item Area Imported form Layouts */}
                <CooliItemArea design={'home_2'} />

                {/* Count Area Imported form Layouts */}
                <CountArea />

                {/* Facitilities Area Imported form Layouts */}
                <FacilitiesArea />

                {/* Cta Area Imported form Layouts */}
                <CtaArea service={false} />

                {/* History Area Imported form Layouts */}
                <HistoryArea />
            </Section>
        );
    }
}

export default About;