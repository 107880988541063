import React, {Component} from "react";
import {Link} from "react-router-dom";

class Header extends Component {
    render() {
        const sliderItem = (
            <div className="container">
                <div className={this.props.design === 'home_2' ? 'row justify-content-center' : 'row'}>
                    <div className={this.props.design === 'home_2' ? 'col-xl-10 col-lg-11' : 'col-xl-8 col-lg-10'}>
                        <div className={this.props.design === 'home_2' ? 'header-inner wow fadeInUp' : 'header-inner'}>
                            <span className={this.props.design === 'home_2' ? 'sub-title' : 'subtitle'}>
                                Welcome to AirTemp Mechanical Service
                            </span>
                            <h1 className="title wow fadeInRight">Where do we work ?</h1>
                            <p>
                            AirTemp Mechanical services the entire Tri-State Metropolitan area and Central New Jersey
regions with pending licenses for Nassau and Suffolk counties.
                            </p>
                            <div className={this.props.design === 'home_2' ? 'btn-wrapper text-center padding-top-20' : 'btn-wrapper wow fadeInUp desktop-left padding-top-20'}>
                                <Link to={'/service'} className="boxed-btn btn-hrv initiate-scripts">Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="header-area">
                <div className={this.props.design === 'home_2' ? 'header-slider-two' : 'header-slider-area'}>
                    <div className="header-slider-item header-bg" style={{backgroundImage: "url(assets/img/bg/slide-01.jpg)"}}>
                        {sliderItem}
                    </div>
                    <div className="header-slider-item header-bg" style={{backgroundImage: "url(assets/img/bg/slide-02.jpg)"}}>
                        {sliderItem}
                    </div>
                    <div className="header-slider-item header-bg" style={{backgroundImage: "url(assets/img/bg/slide-03.jpg)"}}>
                        {sliderItem}
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;