import React, {Component} from "react";
import Section from "./layouts/Section";
import NewsletterArea from "./layouts/NewsletterArea";

class Contact extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={true} name={'Contact Us'} title={'Please Contact Us'}>
                <div className="contact-area">
                    <div className="container">
                        <div className="row justify-content-around">
                            <div className="col-lg-5">
                                <div className="left-content-area">
                                    <h4 className="title">Contact info</h4>
                                    <ul className="info-list pl-0">
                                        <li>
                                            <div className="single-info-item">
                                                <div className="icon">
                                                    <i className="fa fa-envelope" aria-hidden="true" />
                                                </div>
                                                <h5 className="heading">Email:</h5>
                                            </div>
                                            <div className="content">
                                                <span className="details">info@airtempmechserv.com</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-info-item">
                                                <div className="icon">
                                                    <i className="fa fa-phone" aria-hidden="true" />
                                                </div>
                                                <h5 className="heading">Phone:</h5>
                                            </div>
                                            <div className="content">
                                                <span className="details">+1 (718) 219-4063</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="single-info-item">
                                                <div className="icon">
                                                    <i className="fa fa-map-marker" aria-hidden="true" />
                                                </div>
                                                <h5 className="heading">Address:</h5>
                                            </div>
                                            <div className="content">
                                                <span className="details">895 Liberty Ave, Brooklyn, NY 11208 </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-7">
                                <div className="right-content-area">
                                    <div className="row">
                                        <div className="col-xl-12 col-lg-12">
                                            <h5 className="contact-title">Contact Form</h5>
                                            <form className="contact-form">
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="InputName">
                                                                Your Name
                                                                <span className="requred">*</span>
                                                            </label>

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="InputName"
                                                                placeholder="Name"
                                                                required />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="InputMail">
                                                                Your E-mail
                                                                <span className="requred">*</span>
                                                            </label>

                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="InputMail"
                                                                placeholder="E-mail"
                                                                required />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="InputPhone">
                                                                Phone Number
                                                                <span className="requred" />
                                                            </label>

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="InputPhone"
                                                                placeholder="Phone Number" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label htmlFor="InputSubject">
                                                                Company
                                                                <span className="requred" />
                                                            </label>

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="InputSubject"
                                                                placeholder="Company" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-12 col-lg-12">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleFormControlTextarea1">
                                                                Someting write
                                                                <span className="requred">*</span>
                                                            </label>

                                                            <textarea
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3"
                                                                placeholder="Meassage"
                                                                required />
                                                        </div>
                                                    </div>
                                                    <div className="input_field checkbox_option">
                                                        <input type="checkbox" id="cb1" />
                                                        <label htmlFor="cb1">Save my name, email in this browser</label>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="btn-wrapper text-left">
                                                            <button type="button" className="boxed-btn btn-rounded">Submit </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Newsletter Area Imported from Layouts */}
                <NewsletterArea />
            </Section>
        );
    }
}

export default Contact;