import React, {Component} from "react";

class HistoryArea extends Component {
    render() {
        return (
            <div className="history-area history-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="section-title white">
                                <span className="subtitle">Since 1980 See history</span>
                                <h2 className="title">Watch Our History</h2>
                            </div>
                        </div>
                        <div className="col-lg-8 text-center">
                            <div className="history-content">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing
                                    elit, sed do eiusmod tempor incididunt ut labore
                                    et dolore magna aliqua. Ut enim ad minim veniam
                                </p>
                            </div>
                            <div className="video">
                                <a
                                    href="https://www.youtube.com/watch?v=s6NHxfKUkUo"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="btn-ripple-animate video-popup mfp-iframe play-icon-pulse">
                                    <i className="fa fa-play" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HistoryArea;