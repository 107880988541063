import React, {Component} from "react";
import {Link} from "react-router-dom";

class BlogArea extends Component {
    render() {
        return (
            <div className="blog-area blog-bg padding-top-90 pd-bottom-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="section-title">
                                <span className="subtitle">From blog</span>
                                <h2 className="title">News & Update</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-item">{/* single blog item */}
                                <div className="thumb">
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/img/blog/01.png'}
                                        className="img-fluid"
                                        alt="blog single" />
                                </div>
                                <div className="content">
                                    <div className="meta">
                                            <span className="user">
                                                <i className="fa fa-user" />
                                                Post By: Aron fince
                                            </span>
                                        <span className="date"><i className="fa fa-clock-o" /> HVAC</span>
                                    </div>
                                    <Link to={'/service'} className="initiate-scripts">
                                        <h3 className="title">Why clean &fresh of Your HVAC extra trash...</h3>
                                    </Link>
                                    <Link to={'/service'} className="readmore initiate-scripts">Read More</Link>
                                </div>
                            </div>{/* //. single blog item */}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-item ">{/* single blog item */}
                                <div className="thumb">
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/img/blog/02.png'}
                                        className="img-fluid"
                                        alt="blog single" />
                                </div>
                                <div className="content">
                                    <div className="meta">
                                        <span className="user"><i className="fa fa-user" /> Post By: Aron fince </span>
                                        <span className="date"><i className="fa fa-clock-o" /> HVAC</span>
                                    </div>
                                    <Link to={'/service'} className="initiate-scripts">
                                        <h3 className="title">Why clean &fresh of Your HVAC extra trash...</h3>
                                    </Link>
                                    <Link to={'/service'} className="readmore initiate-scripts">Read More</Link>
                                </div>
                            </div>{/* //. single blog item */}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-item ">{/* single blog item */}
                                <div className="thumb">
                                    <img
                                        src={process.env.PUBLIC_URL + '/assets/img/blog/03.png'}
                                        className="img-fluid"
                                        alt="blog single" />
                                </div>
                                <div className="content">
                                    <div className="meta">
                                        <span className="user"><i className="fa fa-user" /> Post By: Aron fince </span>
                                        <span className="date"><i className="fa fa-clock-o" /> HVAC</span>
                                    </div>
                                    <Link to={'/service'} className="initiate-scripts">
                                        <h3 className="title">Why clean &fresh of Your HVAC extra trash...</h3>
                                    </Link>
                                    <Link to={'/service'} className="readmore initiate-scripts">Read More</Link>
                                </div>
                            </div>{/* //. single blog item */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogArea;