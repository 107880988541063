import React, {Component} from "react";
import Section from "./layouts/Section";
import ServiceArea from "./layouts/ServiceArea";
import ServiceMoreArea from "./layouts/ServiceMoreArea";
import CooliItemArea from "./layouts/CooliItemArea";
import CtaArea from "./layouts/CtaArea";

class Service extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={true} name={'Services'} title={'See our professional services'}>
                <ServiceArea service={false} />
                <ServiceMoreArea />
                <CooliItemArea design={'home_2'} />
                <CtaArea service={true} />
            </Section>
        );
    }
}

export default Service;