import React, {Component} from "react";
import {Link} from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <>
                {/* footer area start */}
                <footer className="footer-area">
                    <div className="footer-top padding-top-100 padding-bottom-65">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget widget">
                                        <div className="about_us_widget">
                                            <Link to={'/'} className="footer-logo initiate-scripts">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/assets/img/footer-logo.png'}
                                                    alt="footer logo" />
                                            </Link>
                                            <ul className="contact_info_list">
                                                <li className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-home" />
                                                    </div>
                                                    <div className="details">
                                                    895 Liberty Ave, Brooklyn, NY 11208
                                                    </div>
                                                </li>
                                                <li className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-phone" />
                                                    </div>
                                                    <div className="details">
                                                        718-219-4063
                                                    </div>
                                                </li>
                                                <li className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-envelope-o" />
                                                    </div>
                                                    <div className="details">
                                                        info@airtempmechserv.com
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget widget widget_nav_menu">
                                        <h4 className="widget-title">Links</h4>
                                        <ul>
                                            <li><Link to={'/service'} className="initiate-scripts">A/C Installation</Link></li>
                                            <li><Link to={'/contact'} className="initiate-scripts">FAQs</Link></li>
                                            <li><Link to={'/about'} className="initiate-scripts">About Us</Link></li>
                                            <li><Link to={'/contact'} className="initiate-scripts">Contact Us</Link></li>
                                            <li><Link to={'/'} className="initiate-scripts">Home</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget widget widget_nav_menu">
                                        <h4 className="widget-title">About Us.</h4>
                                        <ul>
                                            <li><Link to={'/about'} className="initiate-scripts">About Us</Link></li>
                                            <li><Link to={'/blog'} className="initiate-scripts">Blog</Link></li>
                                            <li><Link to={'/contact'} className="initiate-scripts">Contact Us</Link></li>
                                            <li><Link to={'/'} className="initiate-scripts">Sign in</Link></li>
                                            <li><Link to={'/'} className="initiate-scripts">My account</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget widget ">
                                        <h4 className="widget-title">Contact us</h4>
                                        <p>like readable English. Many desktop publishing packages and web page editors now use Lorem
                                            Ipsum sites still in their</p>
                                        <ul className="footer-social">
                                            <li><Link to={'/'} className="initiate-scripts"> <i className="fa fa-facebook-f" aria-hidden="true" /></Link></li>
                                            <li><Link to={'/'} className="initiate-scripts"><i className="fa fa-twitter" aria-hidden="true" /></Link></li>
                                            <li><Link to={'/'} className="initiate-scripts"><i className="fa fa-linkedin" aria-hidden="true" /></Link></li>
                                            <li><Link to={'/'} className="initiate-scripts"><i className="fa fa-pinterest" aria-hidden="true" /></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to={'/'} className="initiate-scripts">Terms & Conditions</Link></li>
                                            <li><Link to={'/'} className="initiate-scripts">Privacy Policy</Link></li>
                                            <li><Link to={'/'} className="initiate-scripts">Sitemap</Link></li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-md-5">
                                    <div className="copyright-text">
                                        <span> &copy; {new Date().getFullYear()} AirTemp Mechanical Service</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* footer area end */}


                {/* back to top area start */}
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up" /></span>
                </div>
                {/* back to top area end */}
            </>
        );
    }
}

export default Footer;