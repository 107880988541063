import React, {Component} from "react";

class ServiceArea extends Component {
    render() {
        const moreServices = (
            <>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-service-item text-center">
                        <div className="icon">
                            <i className="flaticon-maintenance" aria-hidden="true" />
                        </div>
                        <h5 className="title">Commercial</h5>
                        <p className="details">
                        We are a full service company for commercial air conditioning service, maintainence and repair of a wide range of commercial systems.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-service-item text-center">
                        <div className="icon">
                            <i className="flaticon-solar-panel" aria-hidden="true" />
                        </div>
                        <h5 className="title">Residential</h5>
                        <p className="details">
                            We offer a full line of products and services for your home. Give us a call and lets talk about your next home project.
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-service-item text-center ">
                        <div className="icon">
                            <i className="flaticon-technical-support" aria-hidden="true" />
                        </div>
                        <h5 className="title">Repairs</h5>
                        <p className="details">
                           If its broken or just not working up to par give us a call and we can take a look. We have the skills to repair or replace your system as needed.
                        </p>
                    </div>
                </div>
            </>
        );

        return (
            <div className={this.props.service ? 'service-area service-page pd-top-100' : 'service-area pd-top-100 pd-bottom-70'}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8 text-center">
                            <div className="section-title">
                                <span className="subtitle">Service | Maintaince | Installation</span>
                                <h2 className="title">Our HVAC Service offering</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-service-item text-center">
                                <div className="icon">
                                    <i className="flaticon-man-2" aria-hidden="true" />
                                </div>
                                <h5 className="title">HVAC Design & Installation</h5>
                                <p className="details">
                               Our Engineering Division offers customized plans to meet your specific needs
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-service-item text-center">
                                <div className="icon">
                                    <i className="flaticon-customer-service" aria-hidden="true" />
                                </div>
                                <h5 className="title">Refrigeration</h5>
                                <p className="details">
                                    We provided refrigeration installation, service and repair for all of your needs
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-service-item text-center ">
                                <div className="icon">
                                    <i className="flaticon-gauge" aria-hidden="true" />
                                </div>
                                <h5 className="title">Heating</h5>
                                <p className="details">
                               We can fix all makes or models of heating systems. Whatever it’s age we have you covered
                                </p>
                            </div>
                        </div>

                        {!this.props.service ? moreServices : ''}
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceArea;